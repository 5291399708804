<template>
        <section>
            <v-parallax :src="require(`../../../../../assets/images/fintech.png`)"  class="home-parallax" align-center justify-center  :class="{'mobile-layout' :is_screen_small}">
                <v-layout>
                    <v-row no-gutters>
                        <v-col lg="6" md="6" sm="12" xs="12">
                            <v-card height="100%" flat tile xs12 sm12  width="100%" column align="left" justify="left"  class="white--text parallax-text-holder transparent">
                                <v-card-text class="white--text subheading mb-3 text-xs-center parallax-text parralax-left">
                                    <span class="zamu-heading slider-title happiness-orange">ZAMUPAY FOR FINTECHS</span>
                                  <span class="zamu-sub-heading trail-text happiness-white">Payment solutions </span>
                                  <span class="zamu-heading happiness-text happiness-orange">To Simplify </span>
                                  <span class="zamu-heading happiness-text mb-3 happiness-white">Transactions </span>

                                  <span class="image-text mb-8 happiness-white">Zamupay enables borderless transactions for Fintechs to grow their business by substantially improving their efficiency in making online and offline payments.</span>
                                  <span class="" justify="space-around mb-8">
                                      <v-btn large  depressed :color="`${zamuYellow3}`" class="mr-3 started-btn" href="/contact-sales">
                                            Get in Touch
                                        </v-btn>

                                  </span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-parallax>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name:'FintechSlider',
    mixins: [colorMixin, coreMixin],
    computed: {
        is_screen_small() {
        return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly
    },
    }

}
</script>

<style scoped>
.slider-title {
    color: #43237d;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: 23px;
    text-transform: uppercase;
    display: block;
}
.trail-text {
    color: #d29f2a !important;
    font-size: 49px !important;
}
.happiness-text {
    text-transform: uppercase !important;
}
.fintech  .v-parallax__content {
  background: rgb(67 35 125 / 80%);;
}
.happiness-white {
    color: #ffffff !important;
}
.home-parallax {
    height: 600px !important;
    background: #44247c !important;
}
.mobile-layout {
    height: 700px !important;
    background: #44247c !important;


}
</style>