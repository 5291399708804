const colorMixin = {
    data() {
      return {
            zamuPurple: '#44237d',
            zamuPurple1: '#44237c',
            zamuPurple2: '#43237d',
            zamuPurple3: '#5a3289',
            zamuPurple4: '#382465',
            zamuPurple5: '#401a73',


            zamuYellow: '#d09e2a',
            zamuYellow1: '#fece16',
            zamuYellow2: '#ffe06c',
            zamuYellow3: '#d29f2a',
            zamuYellow4: '#fdce16',
            zamuYellow5: '#f4c40a',
            zamuYellow6: '#f4ba0a',

            zamuGrey: '#cdcdcd',
            zamuGrey1: '#fcebaa',

            zamuBlack: '#1b1b1b',
            zamuBlack1: '#161616',

        };
    }
    };
export default colorMixin;
    